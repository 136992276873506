$primary: #67E3D0;

@font-face {
  font-family: 'Arcon-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Arcon-Regular.otf');
}

@font-face {
  font-family: 'Avalon';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Avalon.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Montserrat-Regular.otf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  // font-style: normal;
  font-weight: 600;
  src: url('./fonts/Montserrat-Medium.otf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  // font-style: normal;
  font-weight: 900;
  src: url('./fonts/Montserrat-Bold.otf');
}

body {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  ;
  // background: transparent linear-gradient(212deg, #EDEDED 0%, #000000 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden !important;
  font-family: 'Arcon-Regular';
}

a {
  text-decoration: none;
}

p {
  font-family: 'Arcon-Regular';
  color: #484848;
}

::-webkit-scrollbar {
  display: none;
}

.btn {
  &.red {
    background-color: #A32036;

    .button-text {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }

    &:hover {
      background: rgba(#A32036, 0.6);
    }
  }
}

.MuiGrid-container {
  // max-width: 1200px;
  margin: auto;
  width: 100%;
  overflow-x: hidden;
  // @media(max-width: ;)
}

.MuiGrid-grid-xs-12 {
  max-width: 1200px;
}

.MuiGrid-item {
  padding-right: 100px;
  padding-left: 100px;

  @media(max-width: 768px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media(max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.button-text {
  font-weight: 400;
  margin: 0;
  color: #fff !important;
  text-transform: capitalize;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 0 100px 0;

  form {
    max-width: 400px;
    width: 100%;
    flex: 1
  }

  @media(max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 50px 20px 50px 20px;

    form {
      margin-top: 30px;
    }
  }
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 400px;
  width: 100%;
  align-items: center;

  >div {
    width: 50%;
  }

  .social-icon {
    width: 20px;
    height: 20px;
    margin: 0 20px 0 0;
  }

  .label {
    margin: 0;
  }

  p.info {
    color: #484848;
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;

    img {
      font-size: 20px;
      margin-right: 20px;
    }
  }
}

.label {
  color: #484848 !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  margin-bottom: 10px;
}

.side-navigation {
  position: fixed;
  right: 50px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100;
  @media(max-width: 545px) {
    right: 15px;
  }
  ul {
    list-style: none;

    li {
      a.scroll-link {
        display: block;
        width: 3px;
        height: 20px;
        background: #444444;
        cursor: pointer;
        margin-bottom: 3px;
        position: relative;
        z-index: 10;
        transition: all 400ms ease;

        &.active {
          background: #A32036;
          height: 30px;
          transition: all 400ms ease;
        }

        /*&.first {
          height: 30px;
        }*/

        p {
          opacity: 0;
        }
      }
    }
  }
}

.top-navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 50px;
  position: absolute;
  z-index: 20;
.nav-logo-wrapper {
  // position: absolute;
  // left: 96px;
  // top: -14px;
  // display: flex;
  // align-items: center;
  &.hide {
    display: none !important;
  }
  .nav-logo {
    position: absolute;
    left: 140px;
    width: 50px;
    top: 15px;
  }
  p {
    color: #5C5C5C;
    position: absolute;
    left: 70px;
    top: -7.5px;
    text-transform: uppercase;
    font-family: 'Montserrat-Bold';
    font-size: 28px;
    font-weight: 300;
    margin-right: 10px;
    // line-height: 1.4;
    span {
      margin-right: 8px;
    }
  }
}
  .logo {
    position: absolute;
    left: 100px;
    top: 20px;
    max-width: 50px;
    cursor: pointer;

    @media(max-width: 600px) {
      display: none;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding-right: 100px;

    @media(max-width: 600px) {
      padding-right: 0;
    }

    li {
      margin: 0 20px 0 20px;

      @media(max-width: 600px) {
        margin: 0 10px 0 10px;
      }

      a {
        color: #525252;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        // opacity: 0.8;
        font-family: 'Montserrat-Medium';

        &.active {
          opacity: 1;
          font-weight: 600;
          font-family: 'Avalon';

          @media(max-width: 600px) {
            font-weight: 400;
          }
        }
      }
    }
  }
}

.section-title {
  color: #484848;
  font-size: 36px;
  font-weight: 300;
  text-transform: uppercase;
  font-family: 'Montserrat-Bold';

  span {
    font-size: 14px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
    color: #A7A1A1;
    // font-family: 'Montserrat-Bold';
  }

  @media(max-width: 600px) {
    font-size: 45px;
  }

  @media(max-width: 545px) {
    font-size: 35px;
  }
}

#background-video {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 200vh;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  // width: 100vw;
  opacity: 0.5;
  @media(max-width: 991px) {
    // display: none;
    width: auto;
  }
}

.first-section {
  min-height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F7F7F7;
  position: relative;
  overflow: hidden;
  .arrow-bottom {
    position: absolute;
    bottom: -81px;
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
    border-right: 50px solid #fff;
    transform: rotateZ(90deg);
    z-index: 10;

    @media(max-width: 600px) {
      display: none;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-right: 30px solid #fff;
      z-index: -1;
    }
  }


  .logo {
    max-width: 300px;
    transition: all .3s;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    // position: relative;
    cursor: pointer;
    z-index: 33;
    @media(max-width: 768px) {
      max-width: 120px;
    }

    &.move-to-top {
      // position: absolute;
      left: 140px;
      top: 15px;
      width: 50px;
      transform: none;
      // transform:  translate(-40vw, -39vh);

      // @media(max-height: 700px) {
      //   transform:  translate(-37vw, -37vh);
      // }
      // @media(max-height: 600px) {
      //   transform: translate(-39vw, -32vh);
      // }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  #title {
    color: #5C5C5C;
    font-weight: 300;
    text-align: center;
    line-height: 1.4;
    font-size: 36px;
    font-family: 'Montserrat-Bold';
    transition: all .3s;
    z-index: 3;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    &.move-text {
      left: 100px;
      top: 20px;
      font-size: 28px;
      }
    @media(max-width: 600px) {
      font-size: 28px;
    }
   span.title-span {
      &.hide-text {
        transition: all .3s;
        // transform: scale(0)
        font-size: 0;
      }

    }
  }
}
 // ANIMATION

.second-section {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  background: white;
  @media(max-width: 900px) {
    justify-content: flex-end;
    padding-left: 20px;

    .img {
      display: none;
    }
  }

  .img {
    flex: 1;
    background: url('../assets/img/our-story-image.png') 0% 0% no-repeat;
    background-size: contain;
    min-height: 450px;
    max-width: 50%;
    min-width: 500px;
    margin-left: -50px;
    position: relative;
    z-index: 50;
    @media(max-width: 1500px) {
      max-width: 55%;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h2 {
      margin-top: 0;
    }
    .btn {
      margin-top: 15%;
    }
    @media(max-width: 1500px) {
      max-width: 45%;
      margin-left: auto;
      align-self: baseline;
    }
    @media(max-width: 900px) {
      max-width: 100%;
      width: 90%;
    }
    @media(max-width: 500px) {
      max-width: 100%;
      width: 100%;
    }

  }

  p {
    color: #484848;
    max-width: 590px;
    font-weight: 300;
    font-size: 18px;
    font-family: 'Arcon-Regular';
    @media(max-width: 1300px) {
      font-size: 17px;
    }  
    @media(max-width: 900px) {
      font-size: 18px;
    }
  }
}

.stripe {
  // background: transparent linear-gradient(270deg, #FFFFFF 0%, #67E3D0 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(270deg, #E4445D 0%, #981B2F 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  right: 0;
  bottom: -20px;
  height: 4px;
  border-radius: 5px 0px 0px 5px;
  transition: width .3s;

  /*&.home {
    width: 716px;
  }

  &.about {
    width: 630px;
  }

  &.projects-length {
    width: 537px
  }

  &.careers {
    width: 422px;
  }

  &.blog {
    width: 313px;
  }

  &.contact-length {
    width: 234px;
  }*/

  &.home {
    width: 635px;
  }

  &.about {
    width: 550px;
  }

  &.projects-length {
    width: 455px;
  }

  &.careers {
    width: 340px;
  }

  &.blog {
    width: 234px;
  }
  &.contact-length {
    width: 235px;
  }
}

.stripe-horisontal {
  background: transparent linear-gradient(270deg, #E4445D 0%, #981B2F 100%) 0% 0% no-repeat padding-box;
  position: fixed;
  left: 50px;
  top: 0;
  z-index: 3;
  height: 80%;
  width: 4px;
  border-radius: 5px 1px 5px 5px;

  @media(max-width: 991px) {
    display: none;
  }
}



.arrow-right {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-left: 100px solid #98182F;

  @media(max-width: 600px) {
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid #98182F;
    z-index: -1;
  }
}

.projects {
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 100px;
  background: #F7F7F7;
  h2 {
    span {
      margin-top: 10px;
    }
  }
  .section-title {
    margin-bottom: 0;
    margin-top: 6%;
  }

  .inner {
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    @media(max-width: 768px) {
      margin-top: 30px;
    }
    >div {
      min-width: 250px;
      max-width: 645px;
      flex: 1;
      @media(max-width: 768px) {
        margin-top: 60px;
      }
    }

    @media(max-width: 768px) {
      flex-direction: column-reverse;
    }

    .project-name {
      // text-align: center;
      font-size: 60px;
    }

    img {
      max-width: 72%;
      margin-right: -15%;
      width: 100%;

      @media(max-width: 768px) {
        max-width: 100%;
        margin: auto;
      }
    }

    h3 {
      text-align: left;
      color: #484848;
      font-weight: 300;
      font-size: 18px;
      margin-bottom: 30px;
      margin-top: 0;
      font-family: 'Comfortaa';
      font-weight: 700;
      span {
        font-size: 18px;
        font-family: 'Montserrat-bold';
        text-transform: uppercase;
        display: block;
        margin-bottom: 10px;
        color: #A7A1A1;
        display: block;
        font-weight: bold;
        margin-top: 10px;
      }
    }

    p {
      color: #484848;
      max-width: 645px;
      font-weight: 300;
      font-size: 18px;
      font-family: 'Arcon-Regular';
    }
  }
}

.team {
  // background: #484848;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 10;

  .title-wrapper {
    width: 100%;
    text-align: left;
  }

  .element,
  .team-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media(max-width: 1400px) {
      justify-content: center;
    }

    .team-member {
      margin: 20px;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      width: 30%;
      box-sizing: border-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      min-width: 300px;

      .avatar {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background: rgba(21, 52, 88, 0.4);
        transition: all .3s ease;

        &:hover {
          transform: scale(1.15);
        }

        &.zoran {
          background: url('../assets//img/zoran-tadic.png') no-repeat;
          background-size: contain;
        }

        &.milan {
          background: url('../assets//img/milan-lukic.png') no-repeat;
          background-size: contain;
        }

        &.mladja {
          background: url('../assets//img/mladja.png') no-repeat;
          background-size: contain;
        }

        &.craig {
          background: url('../assets//img/craig.png') no-repeat;
          background-size: contain;
        }
        &.nikola {
          background: url('../assets//img/nikola-jovanovic.png') no-repeat;
          background-size: contain;
        }
      }

      .name {
        font-size: 15px;
        margin-bottom: 0;
        color: #484848;
        font-family: 'Montserrat-Bold';
        font-weight: 600;
      }

      .title {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: 'Montserrat-Bold';
        color: #ADADAD;
        text-align: center;
      }

      .social-wrapper {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #98192F;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        &:hover {
          img {
            opacity: 1;
          }
        }

        img {
          max-width: 20px;
          opacity: 0.8;
          transition: opacity .3s ease;
        }
      }
    }
  }
}

.footer {
  background: #343434;
  // width: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 20px;
  // margin-top: 30px;
  position: relative;
  align-items: center;
  z-index: 50;
@media(max-width: 768px) {
  width: 100%;
  padding: 10px;

}
  .top-border {
    max-width: 1200px;
    width: 100%;
    position: absolute;
    height: 2px;
    background: $primary;
    top: -1px;
  }

  .container {
    width: 82%;
    /*max-width: 1200px;*/
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

    @media(max-width: 768px) {
      flex-direction: column;
      align-items: center;

      .item:first-child {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
          text-align: center !important;
        }

        a {
          text-align: center
        }
      }
    }
    @media(max-width: 600px) {
      padding-left: 0;
    }

    .item {
      flex: 1;

      h2.newsletter-title {
        text-align: left;
        text-transform: uppercase;
        @media(max-width: 768px) {
          text-align: center;
        }
      }
      h2 {
        font-family: 'montserrat-bold';
      }

      .newsletter-wrapper {
        display: flex;

        @media(max-width: 600px) {
          .button-text {
            font-size: 13px !important;
          }

          flex-direction: column;
          min-width: 250px;
          justify-content: center;

          button {
            margin: 20px auto 0 !important;
          }

          .newsletter-input {
            width: auto;
          }
        }

        button {
          background: $primary;
          color: white;
          border: none;
          box-shadow: none;
          border-radius: 4px;
          margin-left: 5px;
          min-width: 100px;
        }

        .newsletter-input {
          width: 100%;
          max-width: 100%;
          height: 40px;
          border-radius: 4px;
          border: none;
          padding-left: 10px;
          outline: none;
        }
      }

      .icons-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        img {
          width: 25px;
          height: 25px;
        }
      }

      h2 {
        text-align: center;
        font-size: 14px;
        color: white;
        margin-bottom: 30px;
        font-weight: 300;
      }

      a {
        display: block;
        margin-bottom: 10px;
        margin-right: 10px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Montserrat-regular';
      }

      &:first-child {
        display: flex;

        h2 {
          text-align: left;
        }

        >div {
          flex: 1;
        }
      }
    }
  }

  p {
    color: white;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Montserrat-regular';
  }
}

.our-services {
  background: #F7F7F7;
  padding-bottom: 80px;
  .section-title {
    margin-top: 6%;
    margin-bottom: 4%;
  }
  .content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 550px) {
      width: 100%;
    }
    .services {
      width: 100%;
      margin: auto;
      margin-bottom: 30px;
      
      .active {
        width: 56%;
        transition: width 0.05s;
      }
      >div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        padding: 20px;
        width: 13%;
        margin: 1%;
        min-height: 230px;
        text-align: center;
        cursor: pointer;
        background: white;
        align-items: center;
        float: left;
        transition: width 0.05s;

        @media(max-width: 991px) {
          justify-content: center;
          min-width: auto;
        }
        @media(max-width: 500px) {
          width: 85% !important;
          padding: 5% !important;
        }

        @media(max-width: 991px) {
          padding: 10px;
        }
        @media(max-width: 545px) {
          padding: 2px;
          width: 30%;
        }

        .image-wrapper {
          flex: 1;
          display: flex;
          align-items: center;
          img {
            width: 75%;
            max-width: 260px;
            margin: 0 auto;
          }
          @media(max-width: 991px) {
            display: none;
          }
          @media(max-width: 500px) {
            width: 50%;
            display: block;
            flex: inherit;
          }
        }

        h3 {
          color: #484848;
          line-height: 1.4;
          font-size: 18px;
          font-family: 'Montserrat-bold';
          min-height: 50px;
          // font-family: 'Montserrat';
          // font-weight: 500;
          @media(max-width: 1300px) {
            font-size: 16px;
          }
          @media(max-width: 650px) {
            font-size: 14px;
          }

          @media(max-width: 545px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  p.text {
    color: #484848;
    font-size: 18px;
    text-align: left;
    transition: height 0.05s;
  }
}

.technologies {
  position: relative;
  padding-bottom: 80px;
  background: white;
  z-index: 50;
  .section-title {
    margin-top: 6%;
  }
  .logos-wrapper {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    >div {
      width: 200px;
      margin: 25px auto;
      text-align: center;

      @media(max-width: 545px) {
        width: 150px;
      }

      img {
        max-width: 130px;
        max-height: 50px;
        transition: all .3s;

        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }

  .arrow-bottom {
    position: absolute;
    top: -125px;
    width: 0;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 100px solid transparent;
    border-right: 50px solid #fff;
    transform: rotateZ(90deg);
    z-index: 10;
    left: 50%;
    margin-left: -50px;

    @media(max-width: 600px) {
      display: none;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-right: 30px solid #fff;
      z-index: -1;
    }
  }
}

.about-us {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: #F7F7F7;
  padding-top: 100px;
  @media(max-width: 545px) {
    padding-top: 50px;
  }
  .cubes {
    position: absolute;
    // width: 100%;
    max-width: 60%;
    top: 50%;
    right: 10%;
    transform: translate(0%, -40%);
    // z-index: -1;
    z-index: 0;

    @media(max-width: 991px) {
      opacity: 0.6;
    }

    @media(max-width: 768px) {
      opacity: .5;
      // max-width: 300px;
      // right: auto;
      // transform: translate(0%, -40%);
    }

    @media(max-width: 545px) {
      display: none;
    }
  }

  .first-section-wrapper {
    max-width: 41%;
    z-index: 2;

    @media(max-width: 768px) {
      max-width: 100%;
    }

    p {
      color: #484848;
      font-size: 18px;
    }
  }
}

.our-process {
  background: #F7F7F7;
  div {
    p {
      width: 100%;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px;
      @media(max-width: 545px) {
        width: 100%;
      }
    }
  }
  .title-wrapper {
    text-align: right;
  }

  .agile-img {
    max-width: 1000px;
    width: 100%;
    display: block;
    margin: auto;
  }

  .agile-text-wrapper {
    width: 100%;
    max-width: 900px;
    margin: auto;
    display: flex;
    margin-bottom: 30px;

    >div {
      flex: 1;

      p {
        text-align: center;
        // color: #484848;
      }
    }
  }
}

.projects-page {
  padding-top: 100px;

  @media(max-width: 545px) {
    padding-top: 50px;
  }

  .project-card {
    // width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 40px 80px;
    margin-bottom: 30px;
    width: 85%;

    @media(max-width: 1400px) {
      padding: 40px 40px;
      width: 100%;
    }

    @media(max-width: 550px) {
      width: inherit;
    }
    .section-title {
      font-size: 28px;
      margin-top: 0;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 991px) {
      flex-direction: column;
    }
  }

  .text {
    max-width: 55%;

    @media(max-width: 991px) {
      max-width: 100%;
    }

    &.right {
      p {
        text-align: right;
      }
    }
    p {
      font-size: 18px;
    }
    @media(max-width: 1440px) {
      p {
        font-size: 15px;
      }
    }
  }

  .ivoy-app-image {
    max-width: 586px;
    width: 100%;
    @media(max-width: 1400px) {
      max-width:400px;
    }
  }

  .logistics-image {
    max-width: 500px;
    width: 100%;
  }
}

.contact-page {
  min-height: 100vh;
  padding-top: 100px;
  @media(max-width: 545px) {
    padding-top: 50px;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    @media(max-width: 1025px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .item {
      flex: 1;
    }
    .input-wrapper {
      margin-bottom: 20px;

    }
    label {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
  #g-recaptcha {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .bg-image {
    width: 100%;
    height: 250px;
    background: url('../assets/img/office-view.png') 50% 50% no-repeat;
    background-size: cover;
    margin-top: 50px;
  }
  .contact-wrapper {
    display: flex;
    // justify-content: center;
    // align-items: center;
    width: 250px;
    margin: auto;
    flex-direction: column;

    .usce-img {
      max-width: 250px;
      margin-bottom: 30px;
    }
    @media(max-width: 650px) {
      flex-direction: column-reverse;
    }

    .contact-info {
      @media(max-width: 650px) {
        justify-content: center;
        width: auto;
      }
    }

    .contact-info>div {
      width: 100%;
      margin-left: 30px;

      @media(max-width: 650px) {
        margin-left: auto;
        margin-bottom: 30px;
      }
    }
  }
  .contact-form {
    width: 75%;
    margin-left: auto;
    padding-right: 20px;
    @media(max-width: 991px) {
      width: 100%;
      margin-right: auto;
      padding-right: 0;
    }
  }
  .map-image {
    max-width: 650px;
    width: 100%;

    @media(max-width: 1350px) {
      max-width: 450px;
    }

    @media(max-width: 1100px) {
      max-width: 350px;
    }

    @media(max-width: 991px) {
      display: none;
    }
  }
}

.careers-page {
  min-height: 100vh;
  padding-top: 100px;
  background: url('../assets/img/dots-grey.svg') 0% 0% no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media(max-width: 545px) {
    padding-top: 50px;
  }
  .section-title {
    max-width: 70%;

    @media(max-width: 768px) {
      max-width: 100%;
    }
  }

  .inner {
    /*display: flex;*/
    align-items: center;
    // justify-content: space-evenly;
    @media(max-width: 600px) {
      flex-direction: column;
    }
    .office-img {
      max-width: 50%;
      width: 100%;
      margin-right: 20px;
      @media(max-width: 600px) {
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
      }
    }
    .section-head {
      padding: 0 20px;
      box-sizing: border-box;
      box-shadow: 0 3px 6px rgba(0,0,0,.16);
      border-radius: 10px;
      background-color: #fff;
      padding: 0 20px;
      margin-bottom: 10%;
      .job-title {
        display: flex;
        justify-content: space-between;
        h3 {
          text-align: left;
          color: #a32036;
          font-weight: 300;
          text-transform: uppercase;
          font-family: 'Montserrat-Bold';
          @media(max-width: 500px) {
            font-size: 16px;
          }
        }
        .see-more {
          cursor: pointer;
          @media(max-width: 500px) {
            display: none;
          }
        }  
      }
      .section-body{  
        display: flex;
        justify-content: space-around;
        align-items: center;
        .team-illustration {
          @media(max-width: 1500px) {
            display: none;
          }
        }
        .job-description {
          width: 50%;
          padding-bottom: 5%;
          @media(max-width: 1500px) {
            width: 100%;
          }
          p {
            font-size: 18px;
          }
          ul {
            padding: 10px 0 0 0;
            h3 {
              font-size: 24px;
              font-family: 'Avalon';
              margin-bottom: 0;
            }
            li {
              margin-left: 30px;
              margin-top: 0;
              margin-bottom: 0;
              line-height: 1;
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    } 
  }     
  .careers-image-wrapper {
    display: flex;
    flex-direction: column;
    /*width: 50%;*/
    img {
      /*max-width: 500px;*/
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
      @media(max-width: 600px) {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }
}

.blog-page {
  min-height: 100vh;
  padding-top: 100px;
  background: url('../assets/img/dots-grey.svg') 0% 0% no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media(max-width: 545px) {
    padding-top: 50px;
  }
  .inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .blog-post {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    .post-image {
      max-width: 100%;
    }
    .post-info {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      p.category {
        flex: 1;
        color: gray;
        font-size: 12px;
        text-transform: uppercase;
        // font-family: 'Montserrat-Medium';
        font-weight: 600;
        hr {
          margin-bottom: 0;
        }
      }
      p.date {
        flex: 1;
        text-align: right;
        color: gray;
        font-size: 12px;
        font-weight: 600;
      }
    }
    h3.post-title {
      font-family: 'Montserrat-Bold';
    }
    .link-wrapper {
      text-align: right;
    }
    a {
      color: #A31F36;
      font-size: 14px;
    }
  }
}

