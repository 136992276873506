.App {
  width: 100%;
  overflow-x: hidden;
  /* background: url('./assets/img/dots-grey.svg') 0% 0% no-repeat;
  background-attachment: fixed;
  background-size: cover; */
}
/* @media(max-width: 1066px) {
  .App {
     background: url('./assets/img/dots-grey.svg') -400px 0% no-repeat;
     background-attachment: fixed;
  }
}
@media(max-width: 768px) {
  .App {
     background: url('./assets/img/dots-grey.svg') -600px 0% no-repeat;
    background-attachment: fixed;
  }
}
*/
@media(max-width: 600px) {
  .App {
    background: none;
  }
}